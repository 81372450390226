import { PlayersPositions } from "./PlayersPositions.js";

export const TeamColor = {
  TeamColorBlue: "blue",
  TeamColorRed: "red",
  TeamColorGreen: "green",
  TeamColorYellow: "yellow",
};

export const MoveTypesFromMovementApi = {
  GET_SAFE_SQUARE: "GET_SAFE_SQUARE",
  DONT_LOSE_SAFE_SQUARE: "DONT_LOSE_SAFE_SQUARE",
  LESS_DANGER: "LESS_DANGER",
  KILL: "KILL",
  FINISH: "FINISH",
  RELEASE: "RELEASE",
  OTHER: "OTHER",
};

export default {
  TeamColors: [
    TeamColor.TeamColorBlue,
    TeamColor.TeamColorRed,
    TeamColor.TeamColorGreen,
    TeamColor.TeamColorYellow,
  ],
  Teams: [
    PlayersPositions.TL,
    PlayersPositions.TR,
    PlayersPositions.BR,
    PlayersPositions.BL,
  ],
  PlayersPositions: [
    PlayersPositions.BL,
    PlayersPositions.TL,
    PlayersPositions.TR,
    PlayersPositions.BR,
  ],
  Team2List: [PlayersPositions.BL, PlayersPositions.TR],
  Team4List: [
    PlayersPositions.BL,
    PlayersPositions.TL,
    PlayersPositions.TR,
    PlayersPositions.BR,
  ],
  TeamsSmall1: [TeamColor.TeamColorBlue, TeamColor.TeamColorGreen],
  TeamsSmall2: [TeamColor.TeamColorYellow, TeamColor.TeamColorRed],
  DETERMINANT_BALANCE: 100000,
  MOVES_POSITIONS: {
    HOME: 0,
    ANGLE: -1,
    START: 1,
    FINISH: 57,
  },
  ROUND_MOVE_TYPES: {
    KILL: "k",
    RELEASE: "r",
    HOME: "h",
    ROLL: "i",
    OTHER: "o",
  },
  ROUND_COLOR: {
    [TeamColor.TeamColorBlue]: "b",
    [TeamColor.TeamColorRed]: "r",
    [TeamColor.TeamColorGreen]: "g",
    [TeamColor.TeamColorYellow]: "y",
  },
  ARRAY_MOVE_TYPES_FROM_MOVEMENT_API: Object.values(MoveTypesFromMovementApi),
  LobbyRooms: [
    { betAmount: 20, players: 2 },
    { betAmount: 50, players: 4 },
    { betAmount: 75, players: 2 },
    { betAmount: 100, players: 2 },
    { betAmount: 125, players: 2 },
    { betAmount: 150, players: 2 },
  ],
  Server: {
    TOKEN_TO_MOVE: "TOKEN_TO_MOVE",
    TOKEN_EATEN: "TOKEN_EATEN",
    DICE_ROLLING: "DICE_ROLLING",
    DICE_ENABLE: "DICE_ENABLE",
    PLAYER_AUTO_PLAY: "PLAYER_AUTO_PLAY",
    PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
    TIMER_START: "TIMER_START",
    ROOM_DISPOSING: "ROOM_DISPOSING",
    GAME_START: "GAME_START",
    GAME_END: "GAME_END",
  },
  Client: {
    DICE_ROLL: "DICE_ROLL",
    PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
    LEAVE_GAME: "LEAVE_GAME",
    GAME_END: "GAME_END",
    __SYNC__: "__SYNC__",
  },
  Errors: {
    NOT_ENOUGH_MONEY: "NOT_ENOUGH_MONEY",
    NOT_VALID_CURRENCY: "NOT_VALID_CURRENCY",
  },
  CriticalErrors: {
    SERVER_CRIT_ERROR: "SERVER_CRIT_ERROR",
  },
  Values: {
    DICE_VALUE_FOR_LEAVE_BASE: 6,
    FIELD_FINISH: 57,
    FIELD_BASE: 0,
    FIELD_START: 1,
    MAX_SIX_COUNTER: 3,
    NUM_OF_FIELDS: 52,
    FIELD_IN_FRONT_OF_ANGLE: 51,
    FIELD_ANGLE: -1,
    SAFE_FIELDS: [0, 1, 9, 14, 22, 27, 35, 40, 48],
    START_FIELD_INDEXES: {
      [PlayersPositions.TL]: 1,
      [PlayersPositions.TR]: 14,
      [PlayersPositions.BR]: 27,
      [PlayersPositions.BL]: 40,
    },
    Teams: {
      [PlayersPositions.TL]: {
        START_FIELD: "1",
        FINISH_FIELD: "50",
      },
      [PlayersPositions.TR]: {
        START_FIELD: "13",
        FINISH_FIELD: "11",
      },
      [PlayersPositions.BR]: {
        START_FIELD: "26",
        FINISH_FIELD: "24",
      },
      [PlayersPositions.BL]: {
        START_FIELD: "39",
        FINISH_FIELD: "37",
      },
    },
  },
  GameType: {
    SINGLEPLAYER: "SINGLEPLAYER",
    MULTIPLAYER: "MULTIPLAYER",
  },
  TIMER_START_SECONDS: 0,
  TIMER_SECONDS: 15,
  FAST_MOVE_TIMER_SECONDS: 5,
  MULTI_GAME_TIMER_START_SECONDS: 3,
  MULTI_GAME_TIMER_SECONDS: 7,
};
