import * as PIXI from 'pixi.js';
import { gsap } from "gsap";
import { combineLatest, skip } from 'rxjs';
import { PlayersPositions } from "../data/GameProperties";


export class MainDiceContainer extends PIXI.Container {
    constructor(config, soundManager) {
        super();

        this.soundManager = soundManager;
        this.syncServer$ = config.syncServer$;
        this.model = config.model;
        this.updateForce$ = config.updateForce$;

        this.diceValue = 0;
        this._activeAnimation = false;

        this.setup();

        const stream1$ = this.model.dices$[PlayersPositions.TR];
        const stream2$ = this.model.dices$[PlayersPositions.BR];
        const stream3$ = this.model.dices$[PlayersPositions.TL];
        const stream4$ = this.model.dices$[PlayersPositions.BL];

        const combined$ = combineLatest({
            [PlayersPositions.TR]: stream1$,
            [PlayersPositions.BR]: stream2$,
            [PlayersPositions.TL]: stream3$,
            [PlayersPositions.BL]: stream4$,
        })

        combined$
            .subscribe((value) => {
                const { diceValue, diceAnimate } = value[this.model.activePlayerTeam$.value];
                if (diceAnimate === true && this._activeAnimation === false) {
                    this._activeAnimation = diceAnimate;
                    this.runDiceRollingAnim();

                    gsap.delayedCall(0.1, () => this.syncServer$.next());
                    return;
                }

                if (this._activeAnimation === true) {
                    this._activeAnimation = false;

                    this.stopRollingDice({diceValue})
                } else {
                    this.diceValue = diceValue;
                    this.resetDiceScale();
                    this.setDiceValue(this.diceValue % 7);
                    this.syncServer$.next();
                }

                if (diceAnimate === true && this.model.activePlayerTeam$.value === PlayersPositions.BL) {
                    this.model.monitoringPlayerTokenMove$.next(true);
                }
            })

        this.updateForce$
            .subscribe(force => {
                if (force) {
                    if (this.diceRollingTween) {
                        this.diceRollingTween.pause(1);
                        this.diceRollingTween.kill();
                        this.diceRollingTween = null;
                    }
                }

                this.syncServer$.next();
            });

        this.model.enableRollButton$
          .pipe(skip(1))
          .subscribe(active => {
              if (!this.model.gdh.isAutomaticRollActive) {
                  this.runDiceWaitingAnim(active);
              }
          });

        this.model.autoPlayEnabled$
          .pipe(skip(2))
          .subscribe(active => {
              if (active) {
                  if (this.diceWaitingTween) {
                      this.diceWaitingTween.pause(1);
                      this.diceWaitingTween.kill();
                  }
                  this.resetDiceScale();
              }
          });
    }

    setup() {
        this.dice = this.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('dice_0.png'),
            PIXI.Texture.from('dice_1.png'),
            PIXI.Texture.from('dice_2.png'),
            PIXI.Texture.from('dice_3.png'),
            PIXI.Texture.from('dice_4.png'),
            PIXI.Texture.from('dice_5.png'),
        ]));
        this.dice.pivot.set(this.dice.width / 2, this.dice.height / 2);
        this.dice.gotoAndStop(0);

        this.originalWidth = this.dice.width;
        this.originalHeight = this.dice.height;
    }

    setDiceValue(number) {
        if (number === 0) { return; }
        this.dice.gotoAndStop((number - 1));
    }

    show() {
        this.visible = true;
        this.alpha = 0;

        gsap.to(this, { alpha: 1, duration: 0.3, ease: "power2.out" });
    }

    runDiceRollingAnim() {
        this.model.enableRollButton$.next(false);
        this.startRollingTime = Date.now();
        if (this.diceRollingTween) {
            this.diceRollingTween.pause(1);
            this.diceRollingTween.kill();
        }

        this.diceRollingTween = gsap.to(this.dice, {
          width: this.originalWidth * 0.6,
          height: this.originalHeight * 0.6,
          yoyo: true,
          duration: 0.5,
          repeat: -1,
          ease: "Back.easeIn",
          onUpdate: () => {
            this.dice.gotoAndStop(this.randomize(0, 6));
          },
          onComplete: () => {
            this.resetDiceScale();
          },
        });
    }

    stopRollingDice(data) {
        this.diceValue = data.diceValue;

        if (this.diceRollingTween) {
            const currentTime = Date.now();
            const diffTime = currentTime - this.startRollingTime;
            if (diffTime > 2000) {
                this.diceRollingTween.pause(1);
                this.diceRollingTween.kill();
                this.diceRollingTween = null;

                this.resetDiceScale();
                this.setDiceValue(this.diceValue % 7);
                gsap.delayedCall(0.2, () => {
                    this.syncServer$.next();
                });
            } else {
                // gsap.delayedCall((diffTime + (500 - diffTime)) / 1000, () => {
                gsap.delayedCall((diffTime + (300 - diffTime)) / 1000, () => {
                    if (this.diceRollingTween) {
                        this.diceRollingTween.pause(1);
                        this.diceRollingTween.kill();
                        this.diceRollingTween = null;
                    }

                    this.resetDiceScale();
                    this.soundManager.playSound('diceRoll');
                    this.setDiceValue(this.diceValue % 7);
                    gsap.delayedCall(0.2, () => {
                        this.syncServer$.next();
                    });
                });
            }
        } else {
            gsap.delayedCall(0.2, () => this.syncServer$.next());
        }
    }

    runDiceWaitingAnim(active) {
        if (this.diceRollingTween) {
            this.diceRollingTween.pause(1);
            this.diceRollingTween.kill();
        }

        if (this.diceWaitingTween) {
            this.diceWaitingTween.pause(1);
            this.diceWaitingTween.kill();
        }

        if (active) {
            this.dice.width = this.originalWidth;
            this.dice.height = this.originalHeight;

            this.diceWaitingTween = gsap.to(this.dice, {
                width: this.originalWidth * 1.09,
                height: this.originalHeight * 1.09,
                yoyo: true,
                duration: 0.5,
                repeat: -1,
                ease: "none",
                onComplete: () => {
                    this.resetDiceScale();
                },
            });
        } else {
            this.resetDiceScale();
        }
    }

    resetDiceScale() {
        this.dice.width = this.originalWidth;
        this.dice.height = this.originalHeight;
    }

    /**
     * @param min {number}
     * @param max {number}
     * @return {number}
     * @private
     */
     randomize(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    destroy() {
        super.destroy();
        this.removeAllListeners();
        this.removeEventListeners();
        super.destroy();
    }
}
