import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class InsufficientFundsModal extends BaseModal {
    /**
     * @param rootContainer {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     * @param soundManager {SoundManager}
     */
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);

    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('insufficientFunds'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 930);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.insufficient_funds'), {
            fontFamily: "Akshar-Medium",
            fontSize: "60px",
            fill: "#FFD809",
            align: "center",
            stroke: "#2E2F73",
            strokeThickness: 2,
            wordWrapWidth: 900,
            wordWrap: true,
            dropShadow: true,
            dropShadowColor: "#000000",
            dropShadowDistance: 5,
            dropShadowAngle: 0.5
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1122)

        this.confirmButton = this.createBtn(container, 'modals.deposit', () => this.onConfirm());
        this.confirmButton.position.set(this.width / 2 - 220, 1331);

        this.cancelButton = this.createBtn(container, 'modals.close', () => this.onExit());
        this.cancelButton.position.set(this.width / 2 + 220, 1331);

        this.addAnimationItems([bg, text, this.confirmButton, this.cancelButton]);

        return container;
    }

    async onConfirm() {
        this.confirmButton.disabled = true;
        await this.hide();

        // set in url deposit url
        const depositUrl = this.model.gdh.urlParams.get('deposit_url') || process.env.DEPOSIT_GAME_URL;
        if (depositUrl) {
            window.location.href = depositUrl;
        }
    }

    async onExit() {
        this.cancelButton.disabled = true;
        await this.hide();

        const data = this.model.insufficientFunds$.getValue();
        if (typeof data.close === 'function') {
            data.close();
        }

        this.model.clear();
        this.model.insufficientFunds$.next(false)
    }

    show() {
        super.show();
        this.confirmButton.disabled = false;
        this.cancelButton.disabled = false;
    }
}
